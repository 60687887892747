import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import SearchableDropdown from "./SearchableDropdown";
import PasswordShow from "../Common/passwordShow";
import { useDispatch } from "react-redux";
import { useAuthDetails } from "../Common/cookiesHelper";
import { fetchCategoryList } from "../Redux/features/Product/ProductSlice";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 3,
  height: "auto",
};

function CategoryDropdownModal({
  open,
  handleClose,
  handleOpen,
  fetchProducts,
}) {
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = React.useState([]);
  const [error, setError] = React.useState("");
  const [selectedCategoryList, setSelectedCategoryList] = React.useState([]);
  const { getUnAutherisedTokenMessage, handleCoockieExpire, getNetworkError } =
    PasswordShow();
  const { userTypeData, LoginGetDashBoardRecordJson } = useAuthDetails();
  const { loading } = useSelector((state) => state?.productsListData);
  const { fetchCategoryListLoading } = useSelector(
    (state) => state?.productsListData
  );

  const fetchCategoryListData = async () => {
    const inventoryData = new FormData();
    inventoryData.append(
      "merchant_id",
      LoginGetDashBoardRecordJson?.data?.merchant_id
    );

    try {
      const res = await dispatch(fetchCategoryList(inventoryData)).unwrap();
      const filteredCategoryList = res?.result?.filter(item => item.is_lottery === "0")|| [];
      setCategoryList(filteredCategoryList);
    } catch (error) {
      if (error?.status == 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error.status == "Network Error") {
        getNetworkError();
      }
    }
  };

  React.useEffect(() => {
    fetchCategoryListData();
  }, []);

  const handleSelectProductOptions = (value, name) => {
    setSelectedCategoryList((prev) => [...prev, value]);
  };

  const handleDeleteSelectedOption = (id, name) => {
    const filterOptionItems = selectedCategoryList.filter(
      (item) => item?.id !== id
    );
    setSelectedCategoryList(filterOptionItems);
  };

  const handleUpdateVarient = () => {
    const payload = {
      merchant_id: LoginGetDashBoardRecordJson?.data?.merchant_id,
      format: "json",
      category_id: selectedCategoryList?.map((i) => i.id).toString(),
      show_status: "all",
      name: "",
      listing_type: 1,
      offset: 0,
      limit: 50,
      page: "",
      ...userTypeData,
    };
    if (!selectedCategoryList?.length) {
      setError("Select Category");
    } else {
      setError("");
      fetchProducts(payload);
    }
  };

  const resetButton = () => {
    const payload = {
      merchant_id: LoginGetDashBoardRecordJson?.data?.merchant_id,
      format: "json",
      category_id: "all",
      show_status: "all",
      name: "",
      listing_type: 1,
      offset: 0,
      limit: 50,
      page: "",
      ...userTypeData,
    };
    setSelectedCategoryList([]);
    setError("");
    fetchProducts(payload);
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mix-match-modal">
          <Box sx={style}>
            <div className=" mt-2 px-2">
              <Typography id="modal-modal-title" variant="span" component="h2">
                Filter By Category
              </Typography>
              <div className="q-add-categories-single-input">
                <SearchableDropdown
                  title=""
                  keyName="category"
                  name="title"
                  optionList={categoryList}
                  handleSelectProductOptions={handleSelectProductOptions}
                  handleDeleteSelectedOption={handleDeleteSelectedOption}
                  selectedOption={selectedCategoryList}
                  //   error={error}
                  //   handleUpdateError={handleUpdateError}
                  //   placeholder="Search Category"
                  placeholder="Search Category"
                />
              </div>
              {error ? <span className="error-alert">{error}</span> : ""}

              <div
                className="q-category-bottom-header "
                style={{ marginRight: "0px", padding: "20px 0px" }}
              >
                <button
                  className="reset-btn-mix-match"
                  onClick={resetButton}
                  disabled={
                    loading ||
                    fetchCategoryListLoading ||
                    !selectedCategoryList?.length
                  }
                  style={{
                    opacity: !selectedCategoryList?.length ? "0.5" : "1",
                  }}
                >
                  {/* {!loading ? (
      <Box className="loader-box">
        <CircularProgress />
      </Box>
    ) : (
      ""
    )} */}
                  Reset
                </button>
                <div
                  className="q-category-bottom-header "
                  style={{ marginRight: "0px", justifyContent: "end" }}
                >
                  <button
                    className="quic-btn quic-btn-save submit-btn-click"
                    onClick={handleUpdateVarient}
                    disabled={loading || fetchCategoryListLoading}
                  >
                    {loading ? (
                      <Box className="loader-box">
                        <CircularProgress />
                      </Box>
                    ) : (
                      ""
                    )}
                    Apply
                  </button>
                  <button
                    style={{ marginLeft: "10px", backgroundColor: "#878787" }}
                    className="quic-btn quic-btn-save"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default CategoryDropdownModal;
