
export const PERMISSIONS = {

    MENU_NO_SALE : "NO",
    MENU_VENDOR_PAYOUT : "VE",
    MENU_CASH_DROP : "CA",
    MENU_RECEIPTS : "RE",
    MENU_CANCEL_REFUND_PAYMENT : "CF",
    MENU_QUICKADD : "QU",
    MENU_CHANGE_PRICE : "CH",
    MENU_DISCOUNT : "DI",
    MENU_OTD_PRICE : "OT",
    MENU_CASH_PAYMENT : "CT",
    MENU_CREDIT_DEBIT_PAYMENT : "CR",
    MENU_CLOSE_OPEN_SHIFT : "CL",
    MENU_ACCESS_EMPLOYEES : "AE",
    MENU_MANAGE_EMPLOYEES : "ME",
    MENU_TIMESHEET : "TI",
    MENU_DELETE_EMPLOYEES : "DE",
    MENU_EDIT_ITEMS : "ED",
    MENU_ADD_ITEMS : "AD",
    MENU_DISABLE_ITEM : "DS",
    MENU_DELETE_ITEMS : "RI",
    MENU_EDIT_CATEGORIES : "UC",
    MENU_ADD_CATEGORIES : "CC",
    MENU_DISABLE_CATEGORIES : "PC",
    MENU_DELETE_CATEGORIES : "RC",
    MENU_ADD_VENDOR : "CV",
    MENU_EDIT_VENDOR : "UV",
    MENU_DISABLE_VENDOR : "PV",
    MENU_ADD_COUPON : "AO",
    MENU_EDIT_COUPON : "UO",
    MENU_DELETE_COUPON : "RO",
    MENU_ADD_CUSTOMER : "AU",
    MENU_EDIT_CUSTOMER : "UU",
    MENU_DISABLE_CUSTOMER : "PU",
    MENU_DELETE_CUSTOMER : "RU",
    MENU_STORE_INFO : "ST",
    MENU_RECEIPT_TEMPLATE : "PT",
    MENU_REPORTING_TIME : "SE",
    MENU_REGISTER_SETTINGS : "PS",
    MENU_HARDWARE : "HA",
    MENU_SYSTEM_ACCESS : "SY",
    MENU_ALERTS : "AL",
    MENU_ACCESS_TAXES : "T",
    MENU_STORE_OPTIONS : "OP",
    MENU_DISPATCH_CENTER : "CD",
    MENU_COUPON : "C",
    MENU_ADD_POINTS : "IP",
    MENU_REMOVE_POINTS : "DP",
    MENU_QUICK_ADD : "QA",
    MENU_MORE : "MR",
    MENU_ONLINE_STORE_SETUP : "SC",
    MENU_ITEM_DISCOUNT : "ID",
    MENU_ADD_GIFT_CARD_BALANCE : "GB",
    MENU_REMOVE_GIFT_CARD_BALANCE : "RB",
    MENU_GIFT_CARD : "GC",
    MENU_ADD_REMOVE_TAX : "AT",
    MENU_FOOD_EBT : "FO",
    MENU_CASH_EBT : "CE",
    MENU_GIFT_CARD_ISSUE_REDEEM : "GI",
    MENU_CUSTOM_ITEM : "CI",
    MENU_CASH_BACK : "CB",
    MENU_VOID_ORDER : "VD",
    MENU_SHOW_COST : "SH",
    MENU_STATION_SETTING : "SS",
    MENU_CREATE_PO : "EO",
    MENU_VIEW_POS : "VI",
    MENU_EDIT_POS : "EP",
    MENU_RECEIVE_POS : "RP",
    MENU_VOID_POS : "VP",
    MENU_LOTTERY : "LT",
    MENU_21_PLUS_PRODUCT_MANUAL_VERIFICATION : "21",
    MENU_REGISTER_HOME_SCREEN : "RG",
    MENU_ORDERS_HOME_SCREEN : "O",
    MENU_DASHBOARD : "D",
    MENU_CUSTOMERS_HOME_SCREEN : "CU",
    MENU_ATTENDANCE : "TC",
    MENU_REFUND : "RF",
    MENU_ACCESS_REPORTS : "AR",
    MENU_SALES_REPORTS : "SA",
    MENU_INVENTORY_REPORTS : "IN",
    MENU_PAYMENT_REPORTS : "PA",
    MENU_REGISTER_ACTIVITY_REPORTS : "RA",
    MENU_REFUND_REPORTS : "RR",
    MENU_LOYALTY_REPORT : "LR",
    MENU_STORE_CREDIT_REPORTS : "SR",
    MENU_GIFT_CARD_REPORTS : "GR",
    MENU_TAX_REPORTS : "TR",
    MENU_EMPLOYEE_HOURS_REPORTS : "EH",
    MENU_ACCESS_INVENTORY : "AI",
    MENU_MANAGE_CATEGORIES : "MC",
    MENU_MANAGE_PRODUCTS : "MP",
    MENU_MANAGE_ATTRIBUTES : "MA",
    MENU_MANAGE_BRANDS : "MB",
    MENU_MANAGE_TAGS : "MT",
    MENU_ACCESS_VENDORS : "AV",
    MENU_ACCESS_PURCHASE_ORDERS : "AP",
    MENU_INVENTORY_SETTINGS : "IS",
    MENU_ACCESS_COUPONS : "AC",
    MENU_UPDATE_ORDER_STATUS : "UP",
    MENU_CANCEL_ORDER : "CO",
    MENU_ACCESS_STORE_SETTINGS : "AS",
    MENU_PRINT_LABEL : "PL",
    MENU_DASHBOARD_GRAPH : "DG",
    MENU_ACCESS_STOCKTAKE : "AK",
    MENU_CREATE_STOCKTAKE : "CK",
    MENU_VIEW_STOCKTAKE : "VK",
    MENU_EDIT_STOCKTAKE : "EK",
    MENU_VOID_STOCKTAKE : "OK",
    MENU_CUSTOMER_REPORTS : "CM",
    MENU_EMPLOYEE_REPORTS : "ER",
    MENU_ACCESS_LOYALTY_PROGRAM : "ALP",
    MENU_ACCESS_MIX_N_MATCH : "AMM",
    MENU_ADD_MIX_N_MATCH : "AM",
    MENU_EDIT_MIX_N_MATCH : "EM",
    MENU_DELETE_MIX_N_MATCH : "DM",
    MENU_EDIT_LOYALTY_PROGRAM : "EL",
    MENU_ADD_BONUS_POINTS_PROMOTIONS : "AB",
    MENU_EDIT_BONUS_POINTS_PROMOTIONS : "EB",
    MENU_DELETE_BONUS_POINTS_PROMOTIONS : "DB",
    MENU_MANAGE_STORE_ACCESS:"MSA",
    MENU_MANAGE_STORE_ADD_OR_EDIT:"AEE",
    MENU_MANAGE_STORE_ACCESS_PERMISSION:"APE",
  };
  