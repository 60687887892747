import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SpikeCharts from "../SpikeCharts";
import { useSelector } from "react-redux";
import { getRevenue } from "../../../Redux/features/Dashboard/Charts/RevenueChartSlice";
import {
  removeCurrencySign,
  getDefaultDateRange,
  getXAxisData,
  getYAxisTicks,
  getStartDateAndEndDate,
  getPercentValue,
  getLastRangeStartDate,
  formatCurrency,
  dateInYYYYMMDD,
  updateDataObject,
  getMinMaxValues,
  dataExists,
  deleteKey,
} from "../../../Constants/utils";
import { useDispatch } from "react-redux";
import PasswordShow from "../../../Common/passwordShow";

export const RevenueChart = ({
  merchantId,
  activeType,
  presentDate,
  revenueChartInView,
  revenueChartRef,
}) => {
  const { handleCoockieExpire, getUnAutherisedTokenMessage } = PasswordShow();
  const dispatch = useDispatch();
  const [xAxisDates, setXAxisDates] = useState([]);
  const revenueChart = useSelector((state) => state.revenueChart);
  // const [revenueChartState, setRevenueChartState] = useState(false);
  const chartKey = "revenueChart";

  const defaultObj = {
    percent: 0,
    xAxisData: [],
    minValue: 0,
    maxValue: 0,
    yAxisOptions: [],
    type: "number",
    totalValue: formatCurrency(0),
  };
  const [revenueChartData, setRevenueChartData] = useState(defaultObj);
  const [refreshLoading, setRefreshLoading] = useState(false);

  useEffect(() => {
    const bool =
      revenueChart &&
      revenueChart?.revenueData &&
      revenueChart?.revenueData?.length > 3 &&
      !revenueChart.loading;

    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};
    // console.log("hehe oldDashboardData: ", oldDashboardData);

    const dashboardDataExists = dataExists(
      JSON.parse(JSON.stringify(oldDashboardData)),
      merchantId,
      activeType,
      date,
      chartKey
    );
    // console.log("hehe dashboardDataExists: ", dashboardDataExists);

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      return;
    } else {
      if (bool) {
        const getValue = (data, index) => {
          const value =
            data &&
            data.revenueData &&
            data.revenueData.length > 2 &&
            data.revenueData[data.revenueData.length - index];
          return value.total_revenue && parseFloat(value.total_revenue)
            ? parseFloat(value.total_revenue).toFixed(2)
            : 0;
        };

        // for Percentage variance --------------------------------------
        const oldValue = getValue(revenueChart, 2);
        const newValue = getValue(revenueChart, 1);
        const percent = getPercentValue(oldValue, newValue);

        // for X Axis data ----------------------------------------------
        const dataOfXAxis = revenueChart?.revenueData.map((item, index) => ({
          name: xAxisDates[index],
          uv: item.total_revenue
            ? parseFloat(parseFloat(item.total_revenue).toFixed(2))
            : 0,
        }));

        // for Y Axis Data ----------------------------------------------
        const minAndMaxValue =
          dataOfXAxis && dataOfXAxis.length > 0 && getMinMaxValues(dataOfXAxis);

        const temp = getYAxisTicks(minAndMaxValue.maxUv || 0);
        const yAxisOptions = temp.map((val) => parseFloat(val));

        const amt =
          revenueChart?.totalRevenue && !isNaN(revenueChart?.totalRevenue)
            ? revenueChart?.totalRevenue
            : 0;
        const totalValue = formatCurrency(parseFloat(amt || 0));

        const obj = {
          percent: parseFloat(percent)?.toFixed(2),
          xAxisData: dataOfXAxis,
          minValue: minAndMaxValue.minUv,
          maxValue: minAndMaxValue.maxUv,
          yAxisOptions,
          type: "number",
          totalValue,
        };

        const newDashboardData = updateDataObject(
          date,
          activeType,
          merchantId,
          obj,
          chartKey,
          JSON.parse(JSON.stringify(oldDashboardData))
        );

        // console.log("hehe newDashboardData: ", newDashboardData);

        sessionStorage.setItem(
          "dashboardData",
          JSON.stringify(newDashboardData)
        );
        setRevenueChartData(() => obj);
      }
    }
  }, [revenueChart, xAxisDates]);

  // useEffect(() => {
  //   console.log("revenueChartState: ", revenueChartState);
  //   console.log("revenueChartInView: ", revenueChartInView);
  //   if (revenueChartInView && !revenueChartState) {
  //     setRevenueChartState(() => true);
  //   }
  // }, [revenueChartInView, revenueChartState]);

  // setting date range & X Axis data
  useEffect(() => {
    const dates = getDefaultDateRange(activeType, presentDate);
    // setRevenueChartState(() => false);

    if (!dates || !dates.date_range) return;
    const data = getXAxisData(activeType, dates.date_range);
    setXAxisDates(data);
  }, [activeType, presentDate]);

  // revenue api
  const fetchRevenue = async () => {
    try {
      setRefreshLoading(true);
      const dateRange = getDefaultDateRange(activeType, presentDate);
      if (
        dateRange &&
        dateRange?.date_range &&
        merchantId &&
        // revenueChartState
        revenueChartInView
      ) {
        const { endDate } = getStartDateAndEndDate(dateRange?.date_range);
        const data = {
          merchant_id: merchantId,
          start_date: getLastRangeStartDate(dateRange?.date_range),
          end_date: endDate,
          date_range: dateRange?.date_range,
        };

        await dispatch(getRevenue(data)).unwrap();
      }
    } catch (error) {
      if (error?.status === 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status === "Network Error") {
        // getNetworkError();
      }
    } finally {
      setRefreshLoading(false);
    }
  };

  // get revenue chart data
  useEffect(() => {
    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};
    // console.log("date: ", date, merchantId, activeType);
    // console.log("oldDashboardData: ", oldDashboardData);

    const dashboardDataExists = dataExists(
      JSON.parse(JSON.stringify(oldDashboardData)),
      merchantId,
      activeType,
      date,
      chartKey
    );

    // console.log("revenue old data: ", dashboardDataExists);

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      setRevenueChartData(() => dashboardDataExists);
      return;
    }

    if (merchantId && revenueChartInView) {
      fetchRevenue();
    }
  }, [merchantId, revenueChartInView, presentDate, activeType]);

  // upon refresh - remove chart data from session storage
  useEffect(() => {
    if (refreshLoading) {
      deleteKey(merchantId, activeType, presentDate, chartKey);
    }
  }, [refreshLoading, presentDate, activeType, merchantId]);

  return (
    <Grid item xs={12} md={6} lg={6} ref={revenueChartRef}>
      <SpikeCharts
        title={"Total Sales Revenue"}
        growth={revenueChartData.percent}
        mainOutlet={revenueChartData.totalValue}
        amount={revenueChartData.totalValue}
        activeType={activeType}
        xAxisData={revenueChartData.xAxisData}
        maxValue={revenueChartData.maxValue}
        minValue={revenueChartData.minValue}
        yAxisOptions={revenueChartData.yAxisOptions}
        type={revenueChartData.type}
        formatFunction={removeCurrencySign}
        loading={
          revenueChartData.yAxisOptions.length > 0
            ? false
            : revenueChart?.loading || revenueChart.isCancel
        }
        presentDate={presentDate}
        refreshAPI={fetchRevenue}
        refreshLoading={refreshLoading}
        yAxisText={"Metric (e.g., revenue in $)"}
      />
    </Grid>
  );
};
