import HoverToolTip from "../../reuseableComponents/HoverToolTip";

const RadioSelect = ({
  item,
  handleOnlineChange,
  handleRegisterChange,
  disableCategories,
}) => {
  return (
    <>
      <div className="category-checkmark-div ">
        <HoverToolTip
          title={"Show or hide this category on your online store."}
        >
          <label className="category-checkmark-label">
            Online
            <input
              type="checkbox"
              checked={
                item.cat_show_status === "0" || item.cat_show_status === "1"
              }
              onChange={() => {
                if (disableCategories) {
                  handleOnlineChange(item);
                }
              }}
            />
            <span className="category-checkmark"></span>
          </label>
        </HoverToolTip>
        <HoverToolTip title={"Enable this category for in-store sales"}>
          <label className="category-checkmark-label">
            Register
            <input
              type="checkbox"
              checked={
                item.cat_show_status === "0" || item.cat_show_status === "2"
              }
              onChange={() => {
                if (disableCategories) {
                  handleRegisterChange(
                    item.id,
                    item.cat_show_status === "0" || item.cat_show_status === "2"
                      ? "2"
                      : "0",
                    item
                  );
                }
              }}
            />
            <span className="category-checkmark"></span>
          </label>
        </HoverToolTip>
      </div>
    </>
  );
};

export default RadioSelect;
