import { useState } from "react";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import HoverToolTip from "../../../reuseableComponents/HoverToolTip";
import { Box, Modal } from "@mui/material";
import CrossIcon from "../../../Assests/Dashboard/cross.svg";
import { Table } from "react-bootstrap";
const AssignStoreDetails = ({
  selectedView = { title: "" },
  handleReturnStoreNamesUsingMerchantId,
  storeList,
  onViewClick,
}) => {
  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [itemsData, setItemsData] = useState([]);
  const myStyles = {
    position: "absolute",
    top: "47%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "'CircularSTDMedium', sans-serif !important",
  };
  let AuthDecryptDataDashBoardJSONFormat = LoginGetDashBoardRecordJson;
  const merchant_id = AuthDecryptDataDashBoardJSONFormat?.data?.merchant_id;

  const handleClick = async () => {
    handleOpen(true);
    setItemsData(handleReturnStoreNamesUsingMerchantId(storeList.assigned_store))
  };
  return (
    <>
      <div>
        <span
          className="categories-items categories-items-btn viewModal-Btn"
          onClick={handleClick}
        >
          {/* <HoverToolTip title={"Click to view the Store list"}> */}
            <p>View Stores</p>
          {/* </HoverToolTip> */}
        </span>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="view-category-item-modal"
            sx={{ width: { xs: "90%", md: "60%" } }}
            style={myStyles}
          >
            <div
              className="q-add-categories-section-header text-[18px]"
              style={{
                justifyContent: "space-between",
                fontFamily: "CircularSTDBook",
              }}
            >
              <span>
                <span> {storeList.f_name} {storeList.l_name}</span>
              </span>
              <div>
                <div className="flex justify-between gap-4">
                  {/* <select className="custom-selecter cursor-pointer">
                      <option>Recently Added</option>
                      <option> Month</option>
                      <option>Weeks</option>
                    </select> */}

                  <img
                    src={CrossIcon}
                    alt="icon"
                    className="  quic-btn-cancle w-6 h-6 cursor-pointer"
                    onClick={() => handleClose()}
                  />
                </div>
              </div>
            </div>
            <div className="view-category-item-modal-header viewModal-width">
              {itemsData && itemsData.length >= 1 ? (
                <Table striped>
                  <div className="  p-2 my-2">
                    {itemsData?.map((item, index) => (
                      <>
                        <p
                          className="q_view_modal_details"
                          key={index}
                          style={{
                            fontFamily: "CircularSTDMedium !important",
                            cursor: "pointer",
                          }}
                          // onClick={() => hadleRedirect(item?.id)}
                        >
                          {item.name}
                        </p>
                      </>
                    ))}
                  </div>
                </Table>
              ) : (
                <p className="pl-5">No data available</p>
              )}
            </div>

            <div className="q-add-categories-section-middles-footer">
              {/* <button
                  onClick={() => handleClose()}
  
                  className="quic-btn quic-btn-ok"
  
                 
  
                >
                  Ok{" "}
                </button> */}
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default AssignStoreDetails;
