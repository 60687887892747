
  const ThreeDecimalInputHelperFun = (value) => {
    // Remove all non-numeric characters except the dot
    let val = value.replace(/[^\d]/g, "");
  
    // Return an empty string if the input is empty
    if (val === "") {
      return "";
    }
  
    // Remove leading zeros
    val = val.replace(/^0+/, "");
  
    // Ensure the string has enough digits, prepending zeros if necessary
    while (val.length < 5) {
      val = "0" + val;
    }
  
    // Extract integer and decimal parts
    const integerPart = val.slice(0, val.length - 3);
    const decimalPart = val.slice(val.length - 3);
  
    // Combine the integer and decimal parts
    let formattedValue = `${integerPart}.${decimalPart}`;
  
    // Cap the value at 99.999
    if (parseFloat(formattedValue) > 99.999) {
      formattedValue = "99.999";
    }
  
    return formattedValue;
  };
  
  export default ThreeDecimalInputHelperFun;
