import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SpikeCharts from "../SpikeCharts";
import { useSelector } from "react-redux";
import {
  removeCurrencySign,
  getDefaultDateRange,
  getXAxisData,
  getYAxisTicks,
  getStartDateAndEndDate,
  getPercentValue,
  getLastRangeStartDate,
  formatCurrency,
  dateInYYYYMMDD,
  dataExists,
  getMinMaxValues,
  updateDataObject,
  deleteKey,
} from "../../../Constants/utils";
import { useDispatch } from "react-redux";
import PasswordShow from "../../../Common/passwordShow";
import { getGrossProfit } from "../../../Redux/features/Dashboard/Charts/grossProfitChartSlice";

export const GrossProfitChart = ({
  merchantId,
  activeType,
  presentDate,
  grossProfitChartInView,
  grossProfitChartRef,
}) => {
  const { handleCoockieExpire, getUnAutherisedTokenMessage } = PasswordShow();
  const dispatch = useDispatch();
  const [xAxisDates, setXAxisDates] = useState([]);
  const [grossProfitChartState, setGrossProfitChartState] = useState(false);
  const grossProfitChart = useSelector((state) => state.grossProfitChart);
  const chartKey = "grossProfitChart";

  const defaultObj = {
    percent: 0,
    xAxisData: [],
    minValue: 0,
    maxValue: 0,
    yAxisOptions: [],
    type: "number",
    totalValue: formatCurrency(0),
  };
  const [grossProfitChartData, setGrossProfitChartData] = useState(defaultObj);
  const [refreshLoading, setRefreshLoading] = useState(false);

  useEffect(() => {
    if (grossProfitChartInView && !grossProfitChartState) {
      setGrossProfitChartState(() => true);
    }
  }, [grossProfitChartInView, grossProfitChartState]);

  useEffect(() => {
    const bool =
      grossProfitChart &&
      grossProfitChart?.grossProfitData &&
      grossProfitChart?.grossProfitData?.length > 3 &&
      !grossProfitChart.loading;

    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      return;
    } else {
      if (bool) {
        const getValue = (data, index) => {
          const value =
            data &&
            data?.grossProfitData &&
            data?.grossProfitData?.length > 2 &&
            data?.grossProfitData[data?.grossProfitData?.length - index];
          return value?.gross_profit && parseFloat(value?.gross_profit)
            ? parseFloat(value?.gross_profit).toFixed(2)
            : 0;
        };

        // for Percentage variance --------------------------------------
        const oldValue = getValue(grossProfitChart, 2);
        const newValue = getValue(grossProfitChart, 1);
        const percent = getPercentValue(oldValue, newValue);

        // for X Axis data ----------------------------------------------
        const dataOfXAxis = grossProfitChart?.grossProfitData?.map(
          (item, index) => ({
            name: xAxisDates[index],
            uv: item.gross_profit
              ? parseFloat(parseFloat(item.gross_profit).toFixed(2))
              : 0,
          })
        );

        // for Y Axis Data ----------------------------------------------
        const minAndMaxValue =
          dataOfXAxis &&
          dataOfXAxis?.length > 0 &&
          getMinMaxValues(dataOfXAxis);

        const temp = getYAxisTicks(minAndMaxValue?.maxUv || 0);
        const yAxisOptions = temp?.map((val) => parseFloat(val));

        const amt =
          !isNaN(grossProfitChart?.totalGrossProfit) &&
          grossProfitChart?.totalGrossProfit
            ? grossProfitChart?.totalGrossProfit
            : 0;

        const totalValue = formatCurrency(parseFloat(amt || 0));

        const obj = {
          percent: parseFloat(percent)?.toFixed(2),
          xAxisData: dataOfXAxis,
          minValue: minAndMaxValue.minUv,
          maxValue: minAndMaxValue.maxUv,
          yAxisOptions,
          type: "number",
          totalValue,
        };

        const newDashboardData = updateDataObject(
          date,
          activeType,
          merchantId,
          obj,
          chartKey,
          { ...oldDashboardData }
        );
        sessionStorage.setItem(
          "dashboardData",
          JSON.stringify(newDashboardData)
        );
        setGrossProfitChartData(() => obj);
      }
    }
  }, [grossProfitChart, xAxisDates]);

  // setting date range & x-axis data
  useEffect(() => {
    const dates = getDefaultDateRange(activeType, presentDate);
    setGrossProfitChartState(() => false);

    if (!dates || !dates?.date_range) return;
    const data = getXAxisData(activeType, dates?.date_range);
    setXAxisDates(data);
  }, [activeType, presentDate]);

  const fetchGrossProfit = async () => {
    try {
      setRefreshLoading(true);
      const dateRange = getDefaultDateRange(activeType, presentDate);
      if (
        dateRange &&
        dateRange?.date_range &&
        merchantId &&
        grossProfitChartState
      ) {
        const { endDate } = getStartDateAndEndDate(dateRange?.date_range);
        const data = {
          merchant_id: merchantId,
          start_date: getLastRangeStartDate(dateRange?.date_range),
          end_date: endDate,
          date_range: dateRange?.date_range,
        };

        await dispatch(getGrossProfit(data)).unwrap();
      }
    } catch (error) {
      if (error?.status === 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status === "Network Error") {
        // getNetworkError();
      }
    } finally {
      setRefreshLoading(false);
    }
  };

  useEffect(() => {
    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      setGrossProfitChartData(() => dashboardDataExists);
      return;
    }

    if (merchantId && grossProfitChartState) {
      fetchGrossProfit();
    }
  }, [merchantId, grossProfitChartState, presentDate, activeType]);

  // upon refresh - remove chart data from session storage
  useEffect(() => {
    if (refreshLoading) {
      deleteKey(merchantId, activeType, presentDate, chartKey);
    }
  }, [refreshLoading, presentDate, activeType, merchantId]);

  return (
    <Grid item xs={12} md={6} lg={6} ref={grossProfitChartRef}>
      <SpikeCharts
        title={"Profit Generated"}
        growth={grossProfitChartData?.percent}
        mainOutlet={grossProfitChartData?.totalValue}
        amount={grossProfitChartData?.totalValue}
        activeType={activeType}
        xAxisData={grossProfitChartData?.xAxisData}
        maxValue={grossProfitChartData?.maxValue}
        minValue={grossProfitChartData?.minValue}
        yAxisOptions={grossProfitChartData?.yAxisOptions}
        type={grossProfitChartData?.type}
        formatFunction={removeCurrencySign}
        loading={
          grossProfitChartData?.yAxisOptions.length > 0
            ? false
            : grossProfitChart?.loading || grossProfitChart?.isCancel
        }
        presentDate={presentDate}
        refreshAPI={fetchGrossProfit}
        refreshLoading={refreshLoading}
        yAxisText={"Metric (e.g., revenue in $)"}
      />
    </Grid>
  );
};
