import React, { useState, useEffect, lazy, Suspense } from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";

import SelectDropDown from "../../reuseableComponents/SelectDropDown";
import downloadIcon from "../../Assests/Dashboard/download.svg";
import DashDateRangeComponent from "../../reuseableComponents/DashDateRangeComponent";
import VerticalBarChart from "../Dashboard/VerticalBarChart";
import { useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Skeleton } from "@mui/material";
import { useAuthDetails } from "../../Common/cookiesHelper";

const SalesReportMain = lazy(
  () => import("../Reporting/SalesReport/SalesReportMain")
);
const LocationMain = lazy(() => import("./Location/LocationMain"));
const CategoryMain = lazy(() => import("./Category/CategoryMain"));
const VendorsMain = lazy(() => import("./Vendors/VendorsMain"));
const EmployeeSalesMain = lazy(
  () => import("./EmployeeSales/EmployeeSalesMain")
);
const CustomerReportMain = lazy(() => import("./Customer/CustomerReportMain"));
const DiscountReportMain = lazy(() => import("./Discount/DiscountReportMain"));
const SalesByHourMain = lazy(
  () => import("../Reporting/SalesReport/SalebyHour/SalebyHourMain")
);
const ItemsMain = lazy(() => import("../Reporting/ItemSales/MainItemSales"));
const DailyTotalsMain = lazy(
  () => import("../Reporting/DailyReport/DailyTtlReport")
);
const OrderTypeMain = lazy(() => import("../Reporting/ItemType/MainItem"));
const DetailedcategoryMain = lazy(
  () => import("../Reporting/CategoryDetails/MainCatedetails")
);
const DetailedsalespersonMain = lazy(
  () => import("../Reporting/SalesByPerson/MainSalesPerson")
);
const TopsellerMain = lazy(
  () => import("../Reporting/TopSaller/TopSallerReport")
);
const OrderefundreportMain = lazy(
  () => import("../Reporting/OrderRefundReport/OrderRefundReportMain")
);
const ItemrefundreportMain = lazy(
  () => import("../Reporting/RefundSummary/RefundSummary")
);
const TipreportMain = lazy(
  () => import("../Reporting/TipReport/TipReportMain")
);
const CouponreportMain = lazy(
  () => import("../Reporting/CouponReport/CouponReportMain")
);
const EmployeesalespercategoryreportMain = lazy(
  () =>
    import("../Reporting/EmployeeSalesPerCategory/MainEmployeeSalesPerCategory")
);
const CategorySalesSummeryReportMain = lazy(
  () =>
    import("../Reporting/CategorySalesSummeryReport/categorySalesSummeryMain")
);
const DropCashMain = lazy(() => import("../Reporting/DropCash/DropCashMain"));
const PayInMain = lazy(() => import("../Reporting/PayIn/PayInMain"));
const PaymentMethod = lazy(
  () => import("../Reporting/PaymentMehodDetail/PaymentMethodReport")
);
const ProductProfitability = lazy(
  () => import("../Reporting/itemSalesProfitRecord/items_sales_profit_record")
);

const selectReportList = [
  {
    title: "Sales Summary",
    url: "sales-summary",
    className: "Sales-Summary-for-android",
  },
  // {
  //   title: "Location",
  //   url: "location",
  //   className:"Location-for-android"
  // },
  {
    title: "Category",
    url: "category",
    className: "Category-for-android",
  },
  // {
  //   title: "Vendors",
  //   url: "vendors",
  //   className: "Vendors-for-android",
  // },
  {
    title: "Discount",
    url: "discount",
    className: "Discount-for-android",
  },
  {
    title: "Sales by Hour",
    url: "sales-by-hour",
    className: "Sales-by-Hour-for-android",
  },
  {
    title: "Items",
    url: "items",
    className: "Items-for-android",
  },
  {
    title: "Daily Totals",
    url: "daily-totals",
    className: "Daily-Totals-for-android",
  },
  {
    title: "Order Type",
    url: "order-type",
    className: "Order-Type-for-android",
  },
  {
    title: "Detailed Category Report",
    url: "detailed-category-report",
    className: "Detailed-Category-Report-for-android",
  },
  {
    title: "Top Sellers",
    url: "top-seller",
    className: "Top-Sellers-for-android",
  },
  {
    title: "Coupon Report",
    url: "coupon-report",
    className: "Coupon-Report-for-android",
  },
  {
    title: "Tip Report",
    url: "tip-report",
    className: "Tip-Report-for-android",
  },

  // {
  //   title: "Employee Sales",
  //   url: "employee-sales",
  // },
  // {
  //   title: "Customer",
  //   url: "customer",
  // },

  // {
  //   title: "Order Refund Report",
  //   url: "order-refund-report",
  // },
  // {
  //   title: "Item Refund Report",
  //   url: "item-refund-report",
  // },

  // {
  //   title: "Employee Sales Per Category Report",
  //   url: "employee-sales-per-category-report",
  // },
  // {
  //   title: "Category Sales Summary Report",
  //   url: "category-sales-summary-report",
  // },
  // {
  //   title: "Drop Cash",
  //   url: "drop-cash",
  // },
  // {
  //   title: "Pay In Report",
  //   url: "pay-in-report",
  // },
  // {
  //   title: "Payment Method Details",
  //   url: "payment-method-details",
  // },
  // {
  //   title: "Product Profitability Report",
  //   url: "product-profitability-report",
  // },
];

const superadminReportList = [
  {
    title: "Category Sales Summary Report",
    url: "category-sales-summary-report",
    className: "Category-Sales-Summary-Report-for-android",
  },
  // {
  //   title: "Drop Cash",
  //   url: "drop-cash",
  //   className: "Drop-Cash-Report-for-android",
  // },
  // {
  //   title: "Pay In Report",
  //   url: "pay-in-report",
  //   className: "Pay-In-Report-for-android",
  // },
  // {
  //   title: "Payment Method Details",
  //   url: "payment-method-details",
  //   className: "Payment-Method-Details-for-android",
  // },
  {
    title: "Product Profitability Report",
    url: "product-profitability-report",
    className: "Product-Profitability-Report-for-android",
  },
  {
    title: "Detailed Sales Person Report",
    url: "detailed-sales-person-report",
    className: "Detailed-Sales-Person-Report-for-android",
  },
];
const NewSalesReportMain = () => {
  const navigate = useNavigate();
  const { userTypeData } = useAuthDetails();
  const { selectedReport } = useParams();
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [CSVData, setCSVData] = useState(null);
  const [CSVHeaders, setCSVHeader] = useState(null);
  const [CSVFileName, setCSVFileName] = useState("");
  const onDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };
  const [selectedReportList, setSelectedReportList] = useState("Sales Summary");
  useEffect(() => {
    // If the selectedReport is undefined, push the "sales-summary" to the URL
    if (!selectedReport) {
      navigate("/store-reporting/new-sale-report/sales-summary", {
        replace: true,
      });
    }
    setSelectedReportList(
      [...selectReportList, ...superadminReportList].find(
        (item) => item.url === selectedReport
      )?.title
    );
    setCSVData([]);
    setCSVHeader([]);
    setCSVFileName("");
  }, [navigate]);

  const handleOptionClick = (option, dropdown) => {
    switch (dropdown) {
      case "reportList":
        setSelectedReportList(option.title);
        navigate(`/store-reporting/new-sale-report/${option.url}`);
        break;

      default:
        break;
    }
  };

  const renderComponent = () => {
    switch (selectedReport) {
      case "sales-summary":
        return (
          <SalesReportMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      case "location":
        return <LocationMain hide={true} />;
      case "category":
        return (
          <CategoryMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      // case "vendors":
      //   return (
      //     <VendorsMain
      //       hide={true}
      //       setCSVData={setCSVData}
      //       setCSVHeader={setCSVHeader}
      //       setCSVFileName={setCSVFileName}
      //     />
      //   );
      case "employee-sales":
        return (
          <EmployeeSalesMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
          />
        );
      case "customer":
        return (
          <CustomerReportMain
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            hide={true}
          />
        );
      case "discount":
        return (
          <DiscountReportMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      case "sales-by-hour":
        return (
          <SalesByHourMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      case "items":
        return (
          <ItemsMain
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            hide={true}
            setCSVFileName={setCSVFileName}
          />
        );
      case "daily-totals":
        return (
          <DailyTotalsMain
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            hide={true}
            setCSVFileName={setCSVFileName}
          />
        );
      case "order-type":
        return (
          <OrderTypeMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );

      case "detailed-category-report":
        return (
          <DetailedcategoryMain
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            hide={true}
            setCSVFileName={setCSVFileName}
          />
        );
      case "detailed-sales-person-report":
        return (
          <DetailedsalespersonMain
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
            hide={true}
          />
        );
      case "top-seller":
        return (
          <TopsellerMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      case "order-refund-report":
        return (
          <OrderefundreportMain
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            hide={true}
            setCSVFileName={setCSVFileName}
          />
        );

      case "item-refund-report":
        return (
          <ItemrefundreportMain
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            hide={true}
            setCSVFileName={setCSVFileName}
          />
        );

      case "tip-report":
        return (
          <TipreportMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      case "coupon-report":
        return (
          <CouponreportMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      case "employee-sales-per-category-report":
        return (
          <EmployeesalespercategoryreportMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );

      case "category-sales-summary-report":
        return (
          <CategorySalesSummeryReportMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      case "drop-cash":
        return (
          <DropCashMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      case "pay-in-report":
        return (
          <PayInMain
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );

      case "payment-method-details":
        return (
          <PaymentMethod
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );
      case "product-profitability-report":
        return (
          <ProductProfitability
            hide={true}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <Grid container sx={{ py: 2.5, mt: 3.6 }} className="box_shadow_div">
        <Grid item xs={12}>
          <Grid
            container
            direction={{ xs: "column-reverse", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                width: { xs: "100%", md: "unset" },
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                flexWrap: "wrap",
                alignSelf: "start",
                gap: { xs: 1, md: 2 },
                px: { xs: 1, sm: 2.5 },
              }}
            >
              <h1
                style={{ marginBottom: 0 }}
                className="CircularSTDMedium-20px content-center whitespace-nowrap"
              >
                Sales Reports
              </h1>
              <SelectDropDown
                sx={{ pt: 0.5, width: { xs: "100%", md: "22.7rem" } }}
                listItem={
                  userTypeData?.login_type !== "superadmin"
                    ? selectReportList
                    : [...selectReportList, ...superadminReportList]
                }
                onClickHandler={handleOptionClick}
                selectedOption={selectedReportList}
                dropdownFor={"reportList"}
                title={"title"}
              />
            </Grid>

            {CSVData && CSVHeaders.length > 0 && (
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: { xs: "end", md: "center" },
                  gap: 0.5,
                  cursor: "pointer",
                  px: { xs: 1, sm: 2.5 },
                }}
              >
                <CSVLink
                  data={CSVData}
                  className="export-report-android"
                  headers={CSVHeaders}
                  filename={CSVFileName}
                >
                  <div className="flex justify-center items-center flex-nowrap export-report-android">
                    <h1 className="text-[#0A64F9] text-[16px]">
                      Export report
                    </h1>
                    <img
                      style={{ height: "30px", width: "30px" }}
                      src={downloadIcon}
                      alt="downloadIcon"
                    />
                  </div>
                </CSVLink>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container sx={{ paddingY: 3.7 }}>
        <Grid item xs={12}>
          <DashDateRangeComponent onDateRangeChange={onDateRangeChange} />
        </Grid>
      </Grid> */}

      <Grid container>
        <Grid item xs={12}>
          <Suspense
            fallback={
              <>
                <Grid
                  container
                  className="box_shadow_div"
                  sx={{ mt: 2.5, p: 2 }}
                >
                  <Grid item xs={12}>
                    <Skeleton height={200} width={"100%"} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="box_shadow_div"
                  sx={{ mt: 2.5, mx: 0 }}
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <Skeleton height={200} width={"100%"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Skeleton height={200} width={"100%"} />
                  </Grid>
                </Grid>

                <Grid
                  container
                  className="box_shadow_div"
                  sx={{ mt: 3.5, p: 2.5 }}
                >
                  <Grid item xs={12}>
                    <Skeleton height={200} width={"100%"} />
                  </Grid>
                </Grid>
              </>
            }
          >
            {renderComponent()}
          </Suspense>
        </Grid>
      </Grid>
    </>
  );
};

export default NewSalesReportMain;
