import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import SpikeCharts from "../SpikeCharts";
import { useSelector } from "react-redux";
import {
  removeCurrencySign,
  getDefaultDateRange,
  getXAxisData,
  getYAxisTicks,
  getStartDateAndEndDate,
  getPercentValue,
  getLastRangeStartDate,
  formatCurrency,
  dateInYYYYMMDD,
  dataExists,
  getMinMaxValues,
  updateDataObject,
  dataIsMatching,
  deleteKey,
} from "../../../Constants/utils";
import { useDispatch } from "react-redux";
import PasswordShow from "../../../Common/passwordShow";
import { getAvgItemSale } from "../../../Redux/features/Dashboard/Charts/avgItemSaleChartSlice";
import { priceFormate } from "../../../hooks/priceFormate";

export const AvgItemSaleChart = ({
  merchantId,
  activeType,
  presentDate,
  avgItemSaleChartInView,
  avgItemSaleChartRef,
}) => {
  const { handleCoockieExpire, getUnAutherisedTokenMessage } = PasswordShow();
  const dispatch = useDispatch();
  const [xAxisDates, setXAxisDates] = useState([]);
  const [avgItemSaleChartState, setAvgItemSaleChartState] = useState(false);
  const avgItemSaleChart = useSelector((state) => state.avgItemSaleChart);
  const chartKey = "avgItemSaleChart";
  const defaultObj = {
    percent: 0,
    xAxisData: [],
    minValue: 0,
    maxValue: 0,
    yAxisOptions: [],
    type: "number",
    totalValue: formatCurrency(0),
  };
  const [avgItemSaleChartData, setAvgItemSaleChartData] = useState(defaultObj);
  const [refreshLoading, setRefreshLoading] = useState(false);

  useEffect(() => {
    if (avgItemSaleChartInView && !avgItemSaleChartState) {
      setAvgItemSaleChartState(() => true);
    }
  }, [avgItemSaleChartInView, avgItemSaleChartState]);

  useEffect(() => {
    const bool =
      avgItemSaleChart &&
      avgItemSaleChart?.avgItemSaleData &&
      avgItemSaleChart?.avgItemSaleData?.length > 3 &&
      !avgItemSaleChart.loading;

    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // check if new chart data is same as old one
    const chartsDataIsMatching = dataIsMatching(
      avgItemSaleChart?.avgItemSaleData,
      dashboardDataExists.xAxisData,
      "total_revenue"
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0 && chartsDataIsMatching) {
      return;
    } else {
      if (bool) {
        const getValue = (data, index) => {
          const value =
            data &&
            data?.avgItemSaleData &&
            data?.avgItemSaleData?.length > 2 &&
            data?.avgItemSaleData[data?.avgItemSaleData?.length - index];
          return value?.total_revenue && parseFloat(value?.total_revenue)
            ? parseFloat(value?.total_revenue).toFixed(2)
            : 0;
        };

        // for Percentage variance --------------------------------------
        const oldValue = getValue(avgItemSaleChart, 2);
        const newValue = getValue(avgItemSaleChart, 1);
        const percent = getPercentValue(oldValue, newValue);

        // for X Axis data ----------------------------------------------
        const dataOfXAxis = avgItemSaleChart?.avgItemSaleData?.map(
          (item, index) => ({
            name: xAxisDates[index],
            uv: item.total_revenue
              ? parseFloat(parseFloat(item.total_revenue).toFixed(2))
              : 0,
          })
        );

        // for Y Axis Data ----------------------------------------------
        const minAndMaxValue =
          dataOfXAxis &&
          dataOfXAxis?.length > 0 &&
          getMinMaxValues(dataOfXAxis);

        const temp = getYAxisTicks(minAndMaxValue?.maxUv || 0);
        const yAxisOptions = temp?.map((val) => parseFloat(val));

        const amt =
          !isNaN(avgItemSaleChart?.totalAvgItemSale) &&
          parseFloat(avgItemSaleChart?.totalAvgItemSale)
            ? parseFloat(avgItemSaleChart?.totalAvgItemSale)
            : 0;

        const totalValue = !!amt
          ? priceFormate(parseFloat(amt).toFixed(2))
          : "0.0";

        const obj = {
          percent: parseFloat(percent)?.toFixed(2),
          xAxisData: dataOfXAxis,
          minValue: minAndMaxValue.minUv,
          maxValue: minAndMaxValue.maxUv,
          yAxisOptions,
          type: "number",
          totalValue,
        };

        const newDashboardData = updateDataObject(
          date,
          activeType,
          merchantId,
          obj,
          chartKey,
          { ...oldDashboardData }
        );

        sessionStorage.setItem(
          "dashboardData",
          JSON.stringify(newDashboardData)
        );
        setAvgItemSaleChartData(() => obj);
      }
    }
  }, [avgItemSaleChart, xAxisDates]);

  // setting date range
  useEffect(() => {
    const dates = getDefaultDateRange(activeType, presentDate);

    setAvgItemSaleChartState(() => false);

    if (!dates || !dates.date_range) return;
    const data = getXAxisData(activeType, dates?.date_range);
    setXAxisDates(data);
  }, [activeType, presentDate]);

  const fetchAvgItemSale = async () => {
    try {
      setRefreshLoading(true);
      const dateRange = getDefaultDateRange(activeType, presentDate);
      if (
        dateRange &&
        dateRange?.date_range &&
        merchantId &&
        avgItemSaleChartState
      ) {
        const { endDate } = getStartDateAndEndDate(dateRange?.date_range);
        const data = {
          merchant_id: merchantId,
          start_date: getLastRangeStartDate(dateRange?.date_range),
          end_date: endDate,
          date_range: dateRange?.date_range,
        };

        await dispatch(getAvgItemSale(data))?.unwrap();
      }
    } catch (error) {
      console.log("revenue data error: ", error);
      if (error?.status === 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status === "Network Error") {
        // getNetworkError();
      }
    } finally {
      setRefreshLoading(false);
    }
  };

  useEffect(() => {
    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      setAvgItemSaleChartData(() => dashboardDataExists);
      return;
    }

    if (merchantId && avgItemSaleChartState) {
      fetchAvgItemSale();
    }
  }, [merchantId, avgItemSaleChartState, presentDate, activeType]);

  // upon refresh - remove chart data from session storage
  useEffect(() => {
    if (refreshLoading) {
      deleteKey(merchantId, activeType, presentDate, chartKey);
    }
  }, [refreshLoading, presentDate, activeType, merchantId]);

  return (
    <Grid item xs={12} md={6} lg={6} ref={avgItemSaleChartRef}>
      <SpikeCharts
        title={"Items per Transaction"}
        growth={avgItemSaleChartData?.percent}
        mainOutlet={avgItemSaleChartData?.totalValue}
        amount={avgItemSaleChartData?.totalValue}
        activeType={activeType}
        xAxisData={avgItemSaleChartData?.xAxisData}
        maxValue={avgItemSaleChartData?.maxValue}
        minValue={avgItemSaleChartData?.minValue}
        yAxisOptions={avgItemSaleChartData?.yAxisOptions}
        type={avgItemSaleChartData?.type}
        formatFunction={removeCurrencySign}
        loading={
          avgItemSaleChartData?.yAxisOptions.length > 0
            ? false
            : avgItemSaleChart?.loading || avgItemSaleChart?.isCancel
        }
        presentDate={presentDate}
        refreshAPI={fetchAvgItemSale}
        refreshLoading={refreshLoading}
        yAxisText={"Items per Transaction"}
      />
    </Grid>
  );
};
