import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BASE_URL, GET_PERMISSIONS } from "../../../Constants/Config";

export const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}${GET_PERMISSIONS}`,
        requestData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // console.log("Response:", response);

      return response?.data?.permissions;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching permissions"
      );
    }
  }
);

const permissionsSlice = createSlice({
  name: "permissionsData",
  initialState: {
    permissions: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.permissions = action.payload;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default permissionsSlice.reducer;
